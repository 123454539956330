// third-party

import { DatabaseOutlined, IdcardOutlined, LineChartOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import RvHookupTwoToneIcon from '@mui/icons-material/RvHookupTwoTone';
// assets
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import TuneIcon from '@mui/icons-material/Tune';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  TrafficTwoToneIcon,
  AccountTreeIcon,
  RvHookupTwoToneIcon,
  AirlineStopsIcon,
  AddRoadIcon,
  TuneIcon
};

const signal = {
  id: 'signal',
  title: 'Gear List',
  type: 'group',
  
  children: [
    {
      id: 'signalline',
      title: 'Signal',
      type: 'collapse',
      icon: icons.TrafficTwoToneIcon,
      children: [
        {
          id: 'signalglance',
          title: (
            <>
              <FormattedMessage id="Signal" />  Dashboard
            </>
          ),
      
          type: 'item',
          url: '/widget/signalatglance',
           icon: icons.TrafficTwoToneIcon
        },
        {
          id: 'signalgraph',
          title: (
            <>
              <FormattedMessage id="Signal" />  RealTime
            </>
          ),
       
          type: 'item',
          url: '/widget/signalrealtime',
           icon: icons.TrafficTwoToneIcon
        },
        {
          id: 'signalhis',
          title: (
            <>
              <FormattedMessage id="Signal" />  History
            </>
          ),
          type: 'item',
          url: '/widget/signalhistory',
           icon: icons.TrafficTwoToneIcon
        },
        {
          id: 'signalcomp',
          title: (
            <>
              <FormattedMessage id="Signal" />  Compare
            </>
          ),
          type: 'item',
          url: '/widget/signalcompare',
           icon: icons.TrafficTwoToneIcon
        }
        // {
        //   id: 'signalhistory',
        //   title: <FormattedMessage id="Signal History" />,
        //   type: 'item',
        //   url: '/widget/signal',
        //    icon: icons.TrafficTwoToneIcon
        // }
        
       
      ]
    },
    {
      id: 'axle',
      title: 'Axle Counter',
      type: 'collapse',
      icon: icons.AccountTreeIcon,
      children: [
        {
          id: 'AxleCounterAtGlance',
          title: (
            <>
              <FormattedMessage id="Axle" />  Counter Dashboard
            </>
          ),
         
          type: 'item',
          url: '/widget/axleCounter',
          icon: icons.AccountTreeIcon
        },
        {
          id: 'axle',
          title: (
            <>
              <FormattedMessage id="Axle" />  Counter History
            </>
          ),
        
          type: 'item',
          url: '/widget/axleCountAtGlance',
          icon: icons.AccountTreeIcon
        }
       
      ]
    },
    {
      id: 'trackcircuit',
      title: 'Track Circuit',
      type: 'collapse',
      icon: icons.AddRoadIcon,
      children: [
        {
          id: 'trackcircuit',
          title: <FormattedMessage id="Track Circuit Dashboard" />,
          type: 'item',
          // url: '/widget/trackatglance',
         icon: icons.RvHookupTwoToneIcon
        },
        {
          id: 'trackAtGlance',
          title: 'Track Circuit History',
          type: 'item',
          // url: '/widget/trackhistory',
          icon: icons.RvHookupTwoToneIcon
        },
       
       
      ]
    },
    {
      id: 'pointmachine',
      title: 'Point Machine',
      type: 'collapse',
      icon: icons.AirlineStopsIcon,
      children: [
        {
          id: 'pointmachine',
          title: <FormattedMessage id="PM Dashboard" />,
          type: 'item',
          url: '#',
         icon: icons.RvHookupTwoToneIcon
        },
        {
          id: 'pointmachinehistory',
          title: 'Point Machine History',
          type: 'item',
          url: '#',
          icon: icons.RvHookupTwoToneIcon
        }, 
      ]
    },
    {
      id: 'lcgate',
      title: 'LC Gate',
      type: 'collapse',
      icon: icons.TuneIcon,
      children: [
        {
          id: 'lcgatemain',
          title: <FormattedMessage id="LC Gate Dashboard" />,
          type: 'item',
          url: '#',
          icon: icons.RvHookupTwoToneIcon
        },
        {
          id: 'lcgatehistory',
          title: 'LC Gate History',
          type: 'item',
          url: '#',
          icon: icons.RvHookupTwoToneIcon
        },
        
       
      ]
    }
  ]
};

export default signal;
