import axios from 'axios';

const serviceToken = window.localStorage.getItem('serviceToken'); // actual token
//console.log('serviceTokenaxios',serviceToken);



const axiosServices = axios.create({
  baseURL:'https://api.railiotcloud.com/',
  //baseURL:'http://192.168.1.168/',
  // baseURL:'http://122.160.48.7:5050/',
  //baseURL:'https://4cae-223-178-34-252.ngrok-free.app/',
  //  baseURL:'http://122.160.48.7:2390/',
     //baseURL:'https://122.160.48.7:5050/',

   //  baseURL:'https://back.railiotcloud.com/',
   //  withCredentials: true,
  //crossDomain: true

});

 


// ==============================|| AXIOS - FOR JWT SERVICES ||============================== //
// Add authorization header if token exists
if (serviceToken) {
  axiosServices.defaults.headers.common['Authorization'] = `Bearer ${serviceToken}`;
}

// axiosService.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || error)
// );
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios Error:', error);
    return Promise.reject((error.response && error.response.data) || error);
  }
);
export default axiosServices;
