// project import
// import services from 'utils/mockAdapter';
import { DashboardOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //
const icons = {
   DashboardOutlined,

};
const dashboard = {
  id: 'group-dashboard',
  title: 'dashboard',
  type: 'group',
  icon: icons.DashboardOutlined,
  children: [
    {
      id: 'analytics',
          title: 'Dashboard',
          type: 'item',
          icon: icons.DashboardOutlined,
          url: '/dashboard/analytics',
      // children: [
        // {
        //   id: 'default',
        //   title: 'default',
        //   type: 'item',
        //   url: '/dashboard/default',
        //   breadcrumbs: false
        // },
      //   {
      //     id: 'analytics',
      //     title: 'Dashboard',
      //     type: 'item',
      //     url: '/dashboard/analytics',
      //     breadcrumbs: false
      //   }
      // ]
    }
    // {
    //   id: 'components',
    //   title: 'components',
    //   type: 'item',
    //   url: '/components-overview/buttons',
    //   icon: 'goldOutlined',
    //   target: true,
    //   chip: {
    //     label: 'new',
    //     color: 'primary',
    //     size: 'small',
    //     variant: 'combined'
    //   }
    // }
  ]
};

// ==============================|| MOCK SERVICES ||============================== //

// services.onGet('/api/dashboard').reply(200, { dashboard: dashboard });
export default dashboard;
