import React from 'react'

function EditProfile() {
  return (
    <div>
      EditProfile
    </div>
  )
}

export default EditProfile
