import { useEffect, useState } from 'react';


import Loader from 'components/Loader';
import Locales from 'components/Locales';
import Notistack from 'components/third-party/Notistack';
import RTLLayout from 'components/RTLLayout';
// project import
import Routes from 'routes';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import ThemeCustomization from 'themes';
import { dispatch } from 'store';
import { fetchDashboard } from 'store/reducers/menu';

import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// const serviceToken = window.localStorage.getItem('serviceToken'); // actual serviceToken
// console.log('serviceToken',serviceToken);

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   if (!serviceToken) {
  //     // Redirect to the login page if the serviceToken is not present
  //     window.location.href = '/login';
  //   }
  // }, [])
  useEffect(() => {
    dispatch(fetchDashboard()).then(() => {
      setLoading(true);
    });
  }, []);

  if (!loading) return <Loader />;
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
