// third-party

import { DatabaseOutlined, IdcardOutlined, LineChartOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';
import PeopleIcon from '@mui/icons-material/People';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { useNavigate } from 'react-router-dom';


// const userRole=JSON.parse(localStorage.getItem('user')).roles[0];
const user = JSON.parse(localStorage.getItem('user'));
const userRole = user?.roles?.[0];

// assets

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  PeopleIcon,
  RecentActorsIcon,
  PersonAddIcon,
  SupervisedUserCircleIcon
};
// Function to check user role and navigate accordingly


const userconfig = {
  id: 'config',
  title: 'User Configuration',
  type: 'group',
  icon: icons.PeopleIcon,
  children: [
  
  
    {
      id: 'config',
      title: 'Users',
      type: 'collapse',
      icon: icons.PeopleIcon,
      children: [
         ...userRole==='SUPERADMIN' || userRole==='ADMIN'?[
          {
            id: 'register',
            title: <FormattedMessage id="register" />,
            type: 'item',
            url: '/widget/register',
            icon: icons.PersonAddIcon
          },
       ]:[],
       ...[
        
        {
          id: 'Users',
          title: <FormattedMessage id="Users" />,
          type: 'item',
          url: '/widget/userlist',
          icon: icons.RecentActorsIcon
        },
        {
          id: 'Gears List',
          title: <FormattedMessage id="Gears List " />,
          type: 'item',
          url: '/widget/gearList',
          icon: icons.SupervisedUserCircleIcon
        }
      ]
    ]
    }
  ],
 
};

export default userconfig;
