//import formsTables from './forms-tables';
 //import other from './other';
// import pages from './pages';
import report from './report';

// project import
// import applications from './applications';
//import chartsMap from './charts-map';
import signal from './signal';
import settings from './settings';
import visualreport from './visualreport';
// import widget from './widget';
import userconfig from './userconfig';
import dashboard from '_api/dashboard';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    dashboard,
    signal,
    report,
    visualreport,
    userconfig,
    settings
  ]
};
export default menuItems;