// third-party

import { DatabaseOutlined, IdcardOutlined, LineChartOutlined } from '@ant-design/icons';

import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import { FormattedMessage } from 'react-intl';
import SummarizeIcon from '@mui/icons-material/Summarize';

// assets
// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  AssessmentTwoToneIcon,
  SummarizeIcon
};

const report = {
  id: 'report',
  title: 'Report & Analytics',
  type: 'group',
  icon: icons.AssessmentTwoToneIcon,
  children: [
    {
      id: 'report',
      title: <FormattedMessage id="Report" />,
      type: 'collapse',
      // url: '/widget/report',
      icon: icons.SummarizeIcon,
      children: [
        {
          id: 'Report',
          title: <FormattedMessage id="Report" />,
          type: 'item',
          url: '/widget/report',
          icon: icons.AssessmentTwoToneIcon
        },
        {
          id: 'Report At',
          title: <FormattedMessage id="Device History" />,
          type: 'item',
          url: '/widget/lastupdate',
          icon: icons.AssessmentTwoToneIcon
        }
      ]
    }
  ]
};

export default report;
