import AuthGuard from 'utils/route-guard/AuthGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
// project import
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/default')));
const DashboardAnalytics = Loadable(lazy(() => import('pages/dashboard/analytics')));

// render - widget
const AxleCounter = Loadable(lazy(() => import('../pages/widget/axlecounter/axlecounter')));
const AxleCountAtGlance = Loadable(lazy(() => import('../pages/widget/axlecounter/AxleCounterAnalysis')));
const AxleReport = Loadable(lazy(() => import('../pages/widget/reports/LastUpdateReport')));
const Signal = Loadable(lazy(() => import('../pages/widget/signal/SignalAnalysis')));
const Signalcomp = Loadable(lazy(() => import('../pages/widget/signal/SignalAnalysiscomp')));
const SignalAtGlance = Loadable(lazy(() => import('../pages/widget/signal/SignalGlance')));
const SignalRealTime = Loadable(lazy(() => import('pages/widget/signal/SignalRealTime')));

const VisualAxleCounter = Loadable(lazy(() => import('pages/widget/reports/axelcounter/VisualReportsAxel')));
const VisualSignal = Loadable(lazy(() => import('pages/widget/reports/signal/VisualReports')));

const TrackCircuit = Loadable(lazy(() => import('../pages/widget/trackcircuit/axlecounter')));
const TrackAtGlance = Loadable(lazy(() => import('../pages/widget/trackcircuit/AxleCounterAnalysis')));
//const TrackRealTime = Loadable(lazy(() => import('pages/widget/trackcircuit/SignalRealTime')));

const Configuration = Loadable(lazy(() => import('../pages/widget/user/AdminUI_old')));
const DeviceConfiguration=Loadable(lazy(()=>import('../pages/widget/user/DeviceConfiguration')))
const AlertConfiguration=Loadable(lazy(()=>import('../pages/widget/user/Alert')))

const WidgetChart = Loadable(lazy(() => import('pages/widget/chart')));
const Report = Loadable(lazy(() => import('pages/widget/reports/Report')));

const OnlineReport = Loadable(lazy(() => import('pages/widget/reports/LastUpdateReport')));
// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
//const AuthRegister = Loadable(lazy(() => import('pages/auth/register_old')));
const AuthRegister = Loadable(lazy(() => import('pages/widget/user/AWSCognitoRegister')));
const Register=Loadable(lazy(()=>import('../pages/widget/user/Register')))
const EditProfile=Loadable(lazy(()=>import('../pages/widget/user/EditProfile')))

const UserList = Loadable(lazy(() => import('pages/widget/user/UserList')));
const UserStation = Loadable(lazy(() => import('pages/widget/user/UserStation')));
const GearsList = Loadable(lazy(() => import('pages/widget/user/GearList')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const EditRegister = Loadable(lazy(()=>import('pages/widget/user/EditRegister')));
const EditUser= Loadable(lazy(()=>import('pages/widget/user/EditUser')))
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

const AppContactUS = Loadable(lazy(() => import('pages/contact-us')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'default',
              element: <DashboardDefault />
            },
            {
              path: 'analytics',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'widget',
          children: [
            {
              path: 'axleCounter',
              element: <AxleCounter />
            },
            {
              path: 'axleCountAtGlance',
              element: <AxleCountAtGlance />
            },
            {
              path: 'axlereport',
              element: <AxleReport />
            },
            {
              path: 'chart',
              element: <WidgetChart />
            }
          ]
        },
        {
          path: 'widget',
          children: [
            {
              path: 'signalatglance',
              element: <SignalAtGlance />
            },
            {
              path: 'signalrealtime',
              element: <SignalRealTime />
            },
            {
              path: 'signalhistory',
              element: <Signal />
            },
            {
              path: 'signalcompare',
              element: <Signalcomp />
            }
          ]
        },
        {
          path: 'widget',
          children: [
            {
              path: 'trackhistory',
              element: <TrackAtGlance />
            },

            {
              path: 'trackatglance',
              element: <TrackCircuit />
            }
          ]
        },
        {
          path: 'widget',
          children: [
            {
              path: 'report',
              element: <Report />
            },
            {
              path: 'lastupdate',
              element: <OnlineReport />
            },
            {
              path: 'signalcharts',
              element: <VisualSignal />
            },
            {
              path: 'axlecountercharts',
              element: <VisualAxleCounter />
            }
          ]
        },
        {
          path: 'widget',
          children: [
            {
              path: 'Configuration',
              element: <Configuration />
            },
            {
              path: 'deviceconfiguration',
              element: <DeviceConfiguration />
            },
            {
              path: 'alertconfiguration',
              element: <AlertConfiguration />
            },
            {
              path: 'register',
              element: <AuthRegister />
            },
            // {
            //   path: 'register',
            //   element: <Register />
            // },
            {
              path: 'edit-user',
              element: <EditRegister />
            },
            {
              path: 'edituser',
              element: <EditUser />
            },
            {
              path: 'editprofile',
              element: <EditProfile />
            },

            {
              path: 'userlist',
              element: <UserList />
            },
            {
              path: 'userstation',
              element: <UserStation />
            },
            {
              path: 'gearList',
              element: <GearsList />
            }
          ]
        },

       
       
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        },
       
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />
        }
      ]
    },
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />
        }
      ]
    }
  ]
};

export default MainRoutes;
