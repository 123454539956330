// third-party

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CampaignIcon from '@mui/icons-material/Campaign';
import { FormattedMessage } from 'react-intl';
const icons = {
  SettingsSuggestIcon,
  LocationOnIcon,
  CampaignIcon
};

const user = JSON.parse(localStorage.getItem('user'));
const userRole = user?.roles?.[0];
const settings = {
  id: 'config',
  title: 'Device Configure',
  type: 'group',
  icon: icons.SettingsSuggestIcon,
  children: [
    {
      id: 'configuration',
      title: <FormattedMessage id="configuration" />,
      type: 'collapse',
      // url: '/widget/report',
      icon: icons.SettingsSuggestIcon,
      children: [
        ...userRole==='SUPERADMIN' || userRole==='ADMIN'?[
        {
          id: 'Device Configuration',
          title: <FormattedMessage id="Device Location" />,
          type: 'item',
          url: '/widget/deviceconfiguration',
          icon: icons.LocationOnIcon
        },
        {
          id: 'Alert Configuration',
          title: <FormattedMessage id="Alert Configuration" />,
          type: 'item',
          url: '/widget/alertconfiguration',
          icon: icons.CampaignIcon
        },
      ]:[],
      ...[
        {
          id: 'Configuration',
          title: <FormattedMessage id="Device Configuration" />,
          type: 'item',
          url: '/widget/Configuration',
          icon: icons.SettingsSuggestIcon
        }
      ]
      ]
    }
  ],
};

export default settings;
