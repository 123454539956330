// material-ui
import { Box, useMediaQuery } from '@mui/material';

//import Customization from './Customization';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { LAYOUT_CONST } from 'config';
//import Localization from './Localization';
//import MegaMenuSection from './MegaMenuSection';
//import Message from './Message';
import MobileSection from './MobileSection';
//import Notification from './Notification';
import Profile from './Profile';
// project import
import Search from './Search';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';

//import { useMemo } from 'react';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const { isAdmin } = useAuth();
  //console.log(isAdmin);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const localization = useMemo(() => <Localization />, [i18n]);

  //const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Search />}
      {/* {!downLG && megaMenu} */}
      {/* {!downLG && localization} */}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {/* <Notification /> */}
      {/* <Message /> */}
      {/* <Customization /> */}

      {downLG ? <MobileSection /> : isAdmin && <Profile />}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
