// material-ui
//import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { Box, Typography } from '@mui/material';

import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router';

// assets
//import { SearchOutlined } from '@ant-design/icons';



// const Search = () => (
 
  
//   <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
//     {/* <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
//       <OutlinedInput
//         size="small"
//         id="header-search"
//         startAdornment={
//           <InputAdornment position="start" sx={{ mr: -0.5 }}>
//             <SearchOutlined />
//           </InputAdornment>
//         }
//         aria-describedby="header-search-text"
//         inputProps={{
//           'aria-label': 'weight'
//         }}
//         placeholder="Ctrl + K"
//       />  </FormControl> */}
//       <div style={{ display: 'flex', alignItems: 'center' }}>
//       <Typography style={{marginLeft:'50px',color:"#0F029E"}} variant='h3'>Division:</Typography>
//       <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>{userdata.division}</Typography>
      
//       <Typography style={{marginLeft:'100px',color:"#0F029E"}} variant='h3'>Station:</Typography>
//       <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>{stationsString}</Typography>

//       {/* <Typography style={{marginLeft:'100px',color:"#0F029E"}} variant='h3'>Section:</Typography>
//       <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>IBS SMF</Typography> */}
//       </div>

//   </Box>
// );

const Search = () => {

  // ==============================|| HEADER CONTENT - SEARCH ||============================== //
  const userdata=JSON.parse(localStorage.getItem('user'));
   const serviceToken = window.localStorage.getItem('serviceToken')
//debugger;
  const navigate = useNavigate();
    const { logout, user } = useAuth();

    const handleLogout = async () => {
      try {
        
        logout();
        navigate(`/login`, {
          state: {
            from: ''
          }
        });
      } catch (err) {
        console.error(err);
      }
    };


  //console.log('re',userdata)

  if (userdata == null) {
    handleLogout();
  }else if(serviceToken == null){
    handleLogout();
  }

  // Joining the station names into a single string separated by commas
  const stationsString = userdata.station.join(', ');  



  return (
  
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      {/* <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <OutlinedInput
          size="small"
          id="header-search"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: -0.5 }}>
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          placeholder="Ctrl + K"
        />  </FormControl> */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{marginLeft:'50px',color:"#0F029E"}} variant='h3'>Division:</Typography>
        <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>{userdata.division}</Typography>
        
        <Typography style={{marginLeft:'100px',color:"#0F029E"}} variant='h3'>Station:</Typography>
        <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>{stationsString}</Typography>
  
        {/* <Typography style={{marginLeft:'100px',color:"#0F029E"}} variant='h3'>Section:</Typography>
        <Typography style={{marginLeft:'10px',color:"#4395E1"}} variant='h5'>IBS SMF</Typography> */}
        </div>
  
    </Box>
  );

};

export default Search;
