// third-party

import { DatabaseOutlined, IdcardOutlined, LineChartOutlined } from '@ant-design/icons';

import AssessmentIcon from '@mui/icons-material/Assessment';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import { FormattedMessage } from 'react-intl';
import RadarOutlined from '@mui/icons-material/RadarOutlined'

// assets

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  RadarOutlined,
  BarChartOutlined,
  AssessmentIcon
};

const visualreport = {
  id: 'visuali',
  title: 'Visualization',
  type: 'group',
  icon: icons.BarChartOutlined,
  children: [
    {
      id: 'Charts',
      title: <FormattedMessage id="Charts" />,
      type: 'collapse',
      // url: '/widget/report',
      icon: icons.AssessmentIcon,
      children: [
       
        {
          id: 'signalradar',
          title: <FormattedMessage id="Signal" />,
          type: 'item',
          url: '/widget/signalcharts',
          icon: icons.BarChartOutlined
        },
        {
          id: 'axelradar',
          title: <FormattedMessage id="Axle Counter" />,
          type: 'item',
          url: '/widget/axlecountercharts',
          icon: icons.RadarOutlined
        }
      ]
    }
  ]
};

export default visualreport;
