// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import { createContext, useEffect, useReducer } from 'react';

import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import authReducer from 'store/reducers/auth';
import axios from 'utils/axios.js';
import jwtDecode from 'jwt-decode';




const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
  
};
const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decodedToken = jwtDecode(serviceToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};
const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken',serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});


export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          // const response = await axios.get('/api/account/me');
          // const { user } = response.data;
          const userobj = JSON.parse(window.localStorage.getItem('user'));
          // const response = await axios.get('/api/auth/profile');
          const user = userobj;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);
  const login = async (email, password) => {
   // console.log('Axios Base URL:', axios.defaults.baseURL);
   localStorage.clear();
  // debugger;
    const response = await axios.post('/user/login', { email, password });

    const { serviceToken, user } = response.data;

    // Store the token in local storage
    // Store the token and user information in local storage
    // localStorage.setItem("serviceToken", serviceToken);
    localStorage.setItem('token', serviceToken);
    localStorage.setItem('user', JSON.stringify(user));

    // Set the token in the Axios headers for future requests
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (firstname, lastname, phonenumber, station, division, roles, devicersuid, email, password) => {
    try {
    // debugger;
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
     // console.log('Axios Base URL1:', axios.defaults.baseURL);
      const response = await axios.post('/user/adduser', {
        firstname,
        lastname,
        phonenumber,
        station,
        division,
        roles,
        devicersuid,
        email,
        password,
      }, config);

     // console.log('response1', response.data);
      return response.data;
      // return (JSON.stringify(response.data)) // You can return the response if needed
    } catch (error) {
      console.error('Error during registration:', error.message);
      throw error; // Rethrow the error to be handled in the onSubmit catch block
    }
  };



  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    //debugger;
    try {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
     // console.log('Axios Base URL1:', axios.defaults.baseURL);
      //debugger;
      const response = await axios.post('/forgetpassword/sendotp', {
        email
      }, config);
     // console.log('Forget-Password', response);
      return response;
    }
    catch (error) {
      console.error('Error during forget password:', error.message);
      throw error; // Rethrow the error to be handled in the onSubmit catch block
    }


  };
  const verifyOtp = async (email,otp) => {
 // debugger;
    try{
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
     // console.log('Axios Base URL1:', axios.defaults.baseURL);
      const response = await axios.post('/forgetpassword/verifyotp', {
        email
        ,otp
      },config);
     // debugger;
    //  console.log('OTP Verified',response);
      return response;
    } 
    catch (error) {
      console.error('Error during verify otp:', error.message);
      throw error; // Rethrow the error to be handled in the onSubmit catch block
    }


  };

  const forgetPassword = async (email,password) => {
    //debugger
    try{
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      console.log('Axios Base URL1:', axios.defaults.baseURL);
      //debugger;
      const response = await axios.post('/forgetpassword/resetpassword', {
        email
        ,password
      },config);
      //console.log('OTP Verified',response.data);
      return response.data;
    } 
    catch (error) {
      console.error('Error during verify otp:', error.message);
      throw error; // Rethrow the error to be handled in the onSubmit catch block
    }


  };
   
  
  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, method: 'JWT',login, logout, register, resetPassword, updateProfile,verifyOtp ,forgetPassword}}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
