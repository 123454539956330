import axios from 'utils/axios';

export const getZoneinfo = () => {
  const data= JSON.parse(window.localStorage.getItem('user'));
  return data.devicersuid;
};
export const getGroupinfo = () => {
  return window.localStorage.getItem('group');
};
export const getDashCharts = async (payload) => {
  try {
    const response = await axios.post('/get_rsu_dash', payload);
    //  console.log(JSON.stringify(response));
    return response.data.result;
  } catch (error) {
    throw new Error(error);
  }
};
export const getUserList = async () => {
  try {
    // Make sure axios is properly configured with the necessary headers
    
   // console.log('Axios Base URL For USER ', axios.defaults.baseURL);
    const response = await axios.get('/user/all');

    const allUsers = response.data.users;

    // Get the user ID from the stored user record
    //  const storedUser = JSON.parse(localStorage.getItem('user'));
    // const loggedInUserId = storedUser.email;

    // Filter user records based on the logged-in user's email
    // const userRecords = allUsers.filter(user => user.email === loggedInUserId);
    return allUsers;
  } catch (error) {
    throw new Error(error.message); // Access the error message property
  }
}
export const getRole = async () => {
  try {
    const res = await axios.post('/roles/getallrole' );
    console.log('res', res.data);
    return res.data;
    // return response.data.data;
  } catch (error) {
    console.error('Error making API request:', error.message);
  }
}
export const getRsuid = async () => {
  try {
    const response = await axios.post('/rsuentity/getrsuentity');
  
    console.log('API Response:', (response.data));
    return response.data;
  } catch (error) {
    console.error('Error making API request:', error.message);
  }
}
export const getDivision = async () => {
  try {
    const res = await axios.post('/zone/allrailwayzones');
  //  console.log('Division Response', res.data)
    return res.data;
    // return response.data.data;
  } catch (error) {
    // console.error('Error making API request:', error.message);
    console.error('Error making API request:', error.message);
    // Handle errors and return the rejected promise
    throw error;
  }
}

export const getStation = async (divisionno) => {
  
  if (!divisionno) {
    console.error('Invalid division number');
    // You might want to handle this error case appropriately, e.g., return an empty array or throw an error.
    return [];
  }
  try {
    const response = await axios.post(`/zone/stationnamesandcodes/${divisionno}`);

 //   console.log('Response Status:', response.status);
   // console.log('Response Data:', response.data);

    if (response.status === 200) {
      return response.data.data || []; // Ensure you have the correct path to the 'data' array
     
    } else {
      console.error('Unexpected response status:', response.status);
      return [];
    }
  }
  catch (error) {
    console.error('Error fetching data on division number', error.message); // Log the error message
    console.error('Error details:', error); // Log the full error object for more details
    throw error;
  }
}


export const getRsureportconfig = async (payload) => {
  try {

    const response = await axios.post('/devconfig/getconfigurationsbyrsuid', payload,{
   // const response = await axios.post('/configurations/getconfigurationsbyrsuids', payload,{
      headers:{
        "Content-Type":"application/json",
      }
    });
  

    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getAllConfig = async (payload) => {
  try {

    const response = await axios.post('/devconfig/getconfigurationsbyrsuid', payload,{
   // const response = await axios.post('/configurations/getconfigurationsbyrsuids', payload,{
      headers:{
        "Content-Type":"application/json",
      }
    });
  

    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getRsuconfig = async (payload) => {

  try {

    const response = await axios.post('/devconfig/getbygear', payload,{
    
   // const response = await axios.post('/configurations/getconfigurationsbyrsuids', payload,{
      headers:{
        "Content-Type":"application/json",
      }
    });
 

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};



export const getlatestaxle = async () => {

  try {

    const response = await axios.post('/gear/latestaxle',{
    
    });


    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getlatestsignal = async () => {

  try {

    const response = await axios.post('/gear/latestsignal',{
    
    });


    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};





export const getRsuconfigALL = async (payload) => {
  try {
    const response = await axios.post('/devconfig/getConfigByEachGearType',payload,{
   // const response = await axios.post('/configurations/getconfigurationsbyrsuids', payload,{
    
      headers:{
        "Content-Type":"application/json",
      }
    });
    //debugger;
  

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};



export const getReportRSUData = async (payload) => {
  try {
    //  const response = await axios.post('/rsu/getRsutelemetryList', payload,{
    const response = await axios.post('/rsu/reports', payload,{
      // const response = await axios.post('/configurations/getconfigurationsbyrsuids', payload,{
         headers:{
           "Content-Type":"application/json",
         }
       });
      // debugger;
  //const response = ""+payload;
    return response.data.content;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserDeatils = async (payload) => {
  try {
    const response = await axios.post('/user/getuser',payload,{
      headers:{
        "Content-Type":"application/json",
      }
       });
     //  debugger;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};


export const getUserRSUData = async (payload) => {
  try {
    const response = await axios.get('/user/fetchrsu',{
      headers:{
        "Content-Type":"application/json",
      }
       });
     //  debugger;
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};



export const updateUser = async (payload) => {
  try {
    const response = await axios.post('/user/updateuser',payload,{
      headers:{
        "Content-Type":"application/json",
      }
       });
      // debugger;
    return response;

  } catch (error) {
    throw new Error(error);
  }
};




export const setAlertUserRSU = async (payload) => {
  try {
    const response = await axios.post('/alertmsg/save',payload,{
      headers:{
        "Content-Type":"application/json",
      }
       });
     //  debugger;
    return response;
  } catch (error) {
    throw new Error(error);
  }
};




export const getSingleRSUData = async (payload) => {
  try {
   
    const response = await axios.post('/rsu/latesttel', payload,{
         headers:{
           "Content-Type":"application/json",
         }
       });
      // debugger;
  //const response = ""+payload;
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};
export const getprofileData = async () => {
  try {
   
    const response = await axios.get('/user/profile');

    

  
    return response.data.rsu;
  } catch (error) {
    throw new Error(error.message); // Access the error message property
  }
}

export const getLineGraphData = async (payload) => {
 
    try {
     
      const response = await axios.post('/rsu/lineGraphAll', payload,{
        headers:{
          "Content-Type":"application/json",
        }
      });
  
      // const response = ""+payload;
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  
    
  };


  export const getLineGraphTrendData = async (payload) => {
   // debugger;
      try {
       
        const response = await axios.post('/rsu/lineGraphRMSAll', payload,{
          headers:{
            "Content-Type":"application/json",
          }
        });
     //   debugger;
        // const response = ""+payload;
        return response.data;
      } catch (error) {
        throw new Error(error);
      }
    
      
    };


export const getALLRSUData = async (payload) => {

  try {
   
    const response = await axios.post('/rsu/channeldata', payload,{
      headers:{
        "Content-Type":"application/json",
      }
    });
 
    // const response = ""+payload;
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }

  
};

export const getALLALERT = async () => {
  try {
   
    //const response = await axios.post('/rsu_alerts', payload);
    const response = await axios.post('/alerts/allalert',{
      
       });
      
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};



  export const getACKALERT = async (payload) => {
  try {
   
    const response = await axios.patch('/alerts/ack',payload,{
      
         headers:{
           "Content-Type":"application/json",
         }
       });
      
      
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getClearALERT = async (payload) => {
  try {
   
    //debugger;
    //const response = await axios.post('/rsu_alerts', payload);
    const response = await axios.patch('/alerts/clear',payload,{
      
         headers:{
           "Content-Type":"application/json",
         }
       });
       //debugger;
      
    return response;
  } catch (error) {
    throw new Error(error);
  }
};



export const getRSUListByUserStation = async (payload) => {
  try {
   // const response = await axios.post('/rsu_getrsuid', payload);
   const response = ""+payload;
    return response.data.result;
  } catch (error) {
    throw new Error(error);
  }
};





