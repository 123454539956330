// assets
import { EditOutlined, LogoutOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Avatar, Card, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react'


import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import avatar from '../../../../../assets/images/profile/user-profile-1.png';
import EditProfile from './EditProfile';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation,useNavigate } from 'react-router-dom';
import { getprofileData} from '../../../../../_api/rsuAPI';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const userdata = JSON.parse(localStorage.getItem('user'));
// Make sure userdata is not null before accessing its properties
const nameValue = userdata ? userdata.firstname : '';
const emailValue = userdata ? userdata.email : '';
const phoneValue = userdata ? userdata.phonenumber : '';
const lastValue = userdata ? userdata.lastname : '';
const stationValue = userdata ? userdata.station : '';
const role = userdata ? userdata.roles[0] : '';
// const resuid=userdata ? userdata.devicersuid[0]:'';
//const devicersuidArray = userdata ? userdata.devicersuid : [];


//console.log('userData', userdata)

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
const [data,setData] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    if (index === 2) {
     // console.log('Hi');
    } else {
      setSelectedIndex(index);
    }
  };
  const handleEditProfileClick = () => {
    // Navigate to the "/edit-profile" route  emailValue
    console.log(emailValue)
    debugger;
    navigate('/widget/edituser', { state: { emailValue } });
  };


  const fetchData = async () => {
    try {

      const response = await getprofileData();

     
      setData(response);
      //    console.log('response', response);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  useEffect(() => {
    // Fetch data initially
    fetchData();

  }, []); 
  return (

    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 20 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" onClick={handleEditProfileClick}  />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" onClick={handleOpen} />
      </ListItemButton>
      {/* <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <UserSwitchOutlined />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItemButton> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{ border: 'none' }}
      >
        <Box sx={{ ...style, width: 900,maxHeight:500,minHeight:650}}>
        <Stack alignItems="flex-end">
          <ClearIcon sx={{fontSize:'30px'}} onClick={handleClose}/>
            </Stack>
          {/* <h2 id="child-modal-title">Text in a child modal</h2>
          <p id="child-modal-description">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
          </p> */}
          {/* <Button onClick={handleClose}>Close Child Modal</Button> */}
          <Card component={Paper} sx={{ maxWidth: 800 }}>
          
            <Stack alignItems="center">
              <Avatar alt="IoT Profile"></Avatar>
              Role:<Typography sx={{ fontWeight: 'bold' }}>{role}</Typography>
            </Stack>
            <Stack px={2} marginTop="10px">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField id="standard-basic" label="First Name" variant="standard" value={nameValue} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField id="standard-basic" label="Last Name" variant="standard" value={lastValue} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField id="standard-basic" label="Phone Number" variant="standard" value={phoneValue} />
                </Grid>
              

              <Grid item xs={12} md={4} lg={4}>
                  <TextField id="standard-basic" label="Email" variant="standard" value={emailValue} />
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <TextField id="standard-basic" label="Station" variant="standard" value={stationValue} />
                </Grid>

                </Grid>
              <Grid container spacing={2} sx={{ marginTop: '5px' }} >
              <TableContainer component={Paper} sx={{ overflow: 'auto' ,maxHeight:300}}>
      <Table>
      <TableHead>
          <TableRow>
          <TableCell align="center">S.No</TableCell>
          <TableCell align="center">RSU Id</TableCell>
            <TableCell align="center">Location</TableCell>
            <TableCell align="center">Gear Type</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{item.rsuid}</TableCell>
              <TableCell align="center">{item.name}</TableCell>
              <TableCell align="center">{item.gtype}</TableCell>
             
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              </Grid>


              
            </Stack>



          </Card>
        </Box>
      </Modal>

      {/* <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton> */}
      <ListItemButton selected={selectedIndex === 3} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
